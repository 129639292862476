import { BaseError } from '../BaseError';

export function Event404({ eventSlug }: { eventSlug: string }) {
  return (
    <BaseError status="404" title="Event not found">
      <p>
        It looks like the event you are looking for does not exist at this URL.
      </p>
      <p>Please check the browser address that you have the correct URL.</p>
      <p>
        There is no event at: <strong>/{eventSlug}</strong>
      </p>
    </BaseError>
  );
}
