import {
  captureRemixErrorBoundaryError,
  withSentry,
} from '@sentry/remix/cloudflare';
import type { LinksFunction } from '@remix-run/cloudflare';
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react';

import './tailwind.css';
import { FormSaveAndNavigateProvider } from '~/contexts/FormSaveAndNavigateContext';
import { Error404 } from './components/route-error/Error404';

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="overscroll-none">
        <div>{children}</div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function ErrorOutput({ error }: { error: unknown }) {
  if (error instanceof Error) {
    return (
      <>
        <pre>{error.message}</pre>
        <pre>{`Stack:\n\n${error.stack}`}</pre>
      </>
    );
  }

  return <pre>{JSON.stringify(error, null, 2)}</pre>;
}

export const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 error={error.data} />;
    }
  }

  return (
    <div>
      <div>Something went wrong</div>
      <ErrorOutput error={error} />
    </div>
  );
};

function App() {
  return (
    <FormSaveAndNavigateProvider>
      <Outlet />
    </FormSaveAndNavigateProvider>
  );
}

export default withSentry(App);
