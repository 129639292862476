import { BaseError } from '../BaseError';

export function Org404({ orgSlug }: { orgSlug: string }) {
  return (
    <BaseError status="404" title="Organization not found">
      <p>
        It looks like the organization you are looking for does not exist at
        this URL.
      </p>
      <p>Please check the browser address that you have the correct URL.</p>
      <p>
        There is no organization at: <strong>/{orgSlug}</strong>
      </p>
    </BaseError>
  );
}
