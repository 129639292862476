import { PropsWithChildren } from 'react';

export function BaseError({
  status,
  title,
  children,
}: PropsWithChildren<{ title: string; status: string }>) {
  return (
    <div className="flex h-screen flex-col items-center justify-center p-8">
      <div className="flex flex-col items-center gap-8 md:flex-row">
        <div className="text-[100px] font-bold md:text-[200px]">{status}</div>
        <div className="flex flex-col gap-8">
          <div className="flex items-center gap-2">
            <h1 className="text-4xl font-bold">{title}</h1>
          </div>
          <div className="flex flex-col gap-2">{children}</div>
        </div>
      </div>
    </div>
  );
}
