import {
  Base404Error,
  isEvent404Error,
  isOrg404Error,
} from '../../utils/errorResponses';
import { BaseError } from './BaseError';
import { Event404 } from './Error404/Event404';
import { Org404 } from './Error404/Org404';

export function Error404({ error }: { error: Base404Error }) {
  if (isOrg404Error(error)) {
    return <Org404 orgSlug={error.orgSlug} />;
  } else if (isEvent404Error(error)) {
    return <Event404 eventSlug={error.eventSlug} />;
  } else {
    return (
      <BaseError status="404" title="Page not found">
        <p>
          It looks like the page you are looking for does not exist at this URL.
        </p>
        <p>Please check the browser address that you have the correct URL.</p>
      </BaseError>
    );
  }
}
