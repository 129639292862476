type Type404 = 'org' | 'event';

export interface Base404Error {
  type: Type404;
}

export interface Org404Error extends Base404Error {
  type: 'org';
  orgSlug: string;
}

export interface Event404Error extends Base404Error {
  type: 'event';
  eventSlug: string;
}

export function isOrg404Error(error: Base404Error): error is Org404Error {
  return error.type === 'org';
}

export function isEvent404Error(error: Base404Error): error is Event404Error {
  return error.type === 'event';
}

export function createOrg404Response({ orgSlug }: { orgSlug: string }) {
  return Response.json(
    {
      type: 'org',
      orgSlug,
    } satisfies Org404Error,
    { status: 404 },
  );
}

export function createEvent404Response({ eventSlug }: { eventSlug: string }) {
  return Response.json(
    {
      type: 'event',
      eventSlug,
    },
    { status: 404 },
  );
}
